export const menu = [
  {
    title: "Planification",
    subtitle: "Organisation et gestion du planning",
    path: "planification",
    icon: "event",
    links: [
      {
        title: "Planning",
        icon: "event",
        path: "planning",
        bNew: false,
      },
      {
        title: "Incidents",
        icon: "event_busy",
        path: "incidents",
        validationIncident: true,
        bNew: false,
      },
    ],
  },
  {
    title: "Paie",
    subtitle: "Organisation et gestion de la paie",
    path: "pay",
    icon: "euro",
    paie: true,
    links: [
      {
        title: "Gestion des régules de pointage",
        icon: "edit_calendar",
        path: "reg-pointage",
        paie: true,
        bNew: false,
      },
      {
        title: "Gestion des régules de prime",
        icon: "price_check",
        path: "reg-prime",
        paie: true,
        bNew: false,
      },
      {
        title: "Régules de pointage (paie)",
        icon: "request_quote",
        path: "reg-pointage-paie",
        paie: true,
        bNew: false,
      },
    ],
  },
  {
    title: "Statistiques",
    subtitle: "Extraction et récapitulatifs des données",
    path: "statistic",
    icon: "bar_chart",
    responsable: false,
    links: [
      {
        title: "Compteurs",
        icon: "query_stats",
        path: "compteurs",
        responsable: false,
        bNew: false,
      },
      {
        title: "Récap des primes",
        icon: "table_chart",
        path: "recap-prime",
        responsable: true,
        bNew: false,
      },
    ],
  },
  {
    title: "Documents",
    subtitle: "Gestions des documents salarié",
    path: "docs",
    icon: "folder_open",
    links: [
      {
        title: "Contrats",
        icon: "task",
        path: "contracts",
        bNew: false,
        document: true,
      },
      {
        title: "Documents",
        icon: "description",
        path: "documents",
        bNew: false,
        document: true,
      },
    ],
  },
  {
    title: "Éléments",
    subtitle: "Gestions de l'ensemble des éléments du logiciel",
    path: "elements",
    icon: "widgets",
    links: [
      {
        title: "Salariés",
        icon: "group",
        path: "users",
        bNew: false,
        salarie: true,
      },
      {
        title: "Installations",
        icon: "engineering",
        path: "installation",
        bNew: false,
        responsable: true,
      },
      {
        title: "Emplois",
        icon: "work",
        path: "emploi",
        bNew: false,
        responsable: true,
      },
    ],
  },
  {
    title: "Compte",
    subtitle: "Fiches diverses et gestion du compte",
    path: "account",
    icon: "account_circle",
    links: [
      {
        title: "Profil",
        icon: "manage_accounts",
        path: "profile",
        bNew: false,
        salarieSelf: true,
      },
      {
        title: "Paramètres",
        icon: "settings",
        path: "parameters",
        bNew: false,
      },
    ],
  },
];
